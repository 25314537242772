import { create } from "zustand";
import { getDropdownSchema, getDropdownData } from "../actions/dropdown";
import { WorkflowService } from "../service";
import { showError, showSuccess } from "../widgets";

const initialState = {
  dropdown: [],
  dropdownSchema: null,
};

const useDropdownStore = create((set, get) => ({
  ...initialState,
  
  getDropdownSchema: (tableId) => {
    if (!tableId) {
      console.error("tableId is required for getDropdownSchema");
      return;
    }
    const _schema = get().dropdownSchema;
    if (!_schema) {
      getDropdownSchema(tableId);
    }
  },

  setDropdownSchema: (data) => set(() => ({ dropdownSchema: data })),

  addColumn: (tableId, fieldName, fieldType) => {
    if (!fieldName || !fieldType) {
      showError("Field name and type are required");
      return;
    }

    const _schema = get().dropdownSchema;
    if (!_schema) {
      showError("Schema not loaded");
      return;
    }

    const fieldId = fieldName.toLowerCase().split(" ").join("_");

    // Check if column already exists by field ID
    if (_schema[fieldId]) {
      showError(`Column "${fieldName}" already exists!`);
      return;
    }

    // Check if column exists with same name but different ID
    if (_schema[tableId] && _schema[tableId].fields) {
      const existingField = Object.values(_schema)
        .find(field => field.name && field.name.toLowerCase() === fieldName.toLowerCase());
      
      if (existingField) {
        showError(`Column with name "${fieldName}" already exists!`);
        return;
      }
    }

    const field = {
      id: fieldId,
      name: fieldName,
      fieldType: fieldType,
    };
    
    if (!_schema[tableId]) {
      _schema[tableId] = { fields: [] };
    }
    
    _schema[tableId].fields.push(fieldId);
    _schema[fieldId] = field;

    WorkflowService.saveDraftMetadata(tableId, _schema).catch((err) =>
      showError("Failed to save draft!")
    );
    
    WorkflowService.saveLiveMetadata(tableId, _schema)
      .then(() => {
        showSuccess("Column added!");
        getDropdownSchema(tableId);
      })
      .catch((err) => {
        showError("Failed to add column");
        console.log(err);
      });
  },

  getDropdownData: (dropdownName) => {
    if (!dropdownName) {
      console.error("dropdownName is required for getDropdownData");
      return;
    }
    
    console.log('Getting dropdown data for:', dropdownName);
    const _products = get().dropdown;
    if (_products.length === 0) {
      getDropdownData(dropdownName);
    }
  },

  setDropdown: (data) => {
    set(() => ({
      dropdown: data,
    }));
  },

  reset: () => set(() => ({ ...initialState })),
}));

export { useDropdownStore };