import { useEffect } from "react";

import { Chart as ChartJS } from "chart.js/auto";
import "chartjs-adapter-moment";

function LineChart({ id, data, options }) {
  useEffect(() => {
    const ctx = document.getElementById(id);
    const chart = new ChartJS(ctx, {
      type: "line",
      data: data,
      options: { ...options, responsive: true, maintainAspectRatio: true },
    });

    return () => chart.destroy();
  }, []);

  return <canvas id={id}></canvas>;
}

function BarChart({ id, data, options }) {
  useEffect(() => {
    const ctx = document.getElementById(id);
    const chart = new ChartJS(ctx, {
      type: "bar",
      data: data,
      options: { ...options, responsive: true, maintainAspectRatio: true },
    });

    return () => chart.destroy();
  }, []);

  return <canvas id={id}></canvas>;
}

function DoughnutChart({ id, data, options }) {
  useEffect(() => {
    const ctx = document.getElementById(id);
    const chart = new ChartJS(ctx, {
      type: "doughnut",
      data: data,
      options: { ...options, responsive: true, maintainAspectRatio: true },
    });

    return () => chart.destroy();
  }, []);

  return <canvas id={id}></canvas>;
}

export { LineChart, BarChart, DoughnutChart };
