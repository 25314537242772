import { WorkflowService,ItemService } from "../service";
import { useDropdownStore } from "../store";
import { showError } from "../widgets";

export async function getDropdownSchema(tableId) {
  try {
    const schema = await WorkflowService.getLiveMetadata(tableId);
    useDropdownStore.getState().setDropdownSchema(schema);
  } catch {
    showError("Can't find dropdown schema");
  }
}

export function getDropdownData(dropdownName) {
  const setDropdown = useDropdownStore.getState().setDropdown;

  ItemService.getDropdownData(dropdownName)
    .then((data) => {
      // Reverse the format of the data
      const reversedData = data.reverse();
      setDropdown(reversedData);
    })
    .catch((err) => showError("Error in fetching products"));
}
