import React from "react";
import { Menu as AntMenu } from "antd";
// import "antd/es/menu/style/css";

// import styles from './button.module.css';

export function Menu({ ...props }) {
  return <AntMenu {...props} />;
}

export function MenuItem({ ...props }) {
  return <AntMenu.Item {...props} />;
}

export function SubMenu({ ...props }) {
  return <AntMenu.SubMenu {...props} />;
}
