import { create } from "zustand";
import {
  getProductSchema,
  getProducts,
  getSupplierCatalogSchema,
  getSupplierCatalogs,
  getOrderedProductsSchema,
  getOrderProducts,
} from "../actions/inventory";

const initialState = {
  productSchema: null,
  products: [],
  supplierCatalogs: [],
  supplierCatalogSchema: null,
  orderedProductsSchema: null,
  orderedProducts: [],
};

const useInventoryStore = create((set, get) => ({
  ...initialState,

  getProductSchema: (tableId) => {
    const _schema = get().productSchema;
    if (!_schema) {
      getProductSchema(tableId);
    }
  },
  setProductSchema: (data) => set(() => ({ productSchema: data })),

  getProducts: () => {
    const _products = get().products;
    if (_products.length === 0) {
      getProducts();
    }
  },
  setProducts: (data) => set(() => ({ products: data })),

  getSupplierCatalogSchema: (tableId) => {
    const _schema = get().supplierCatalogSchema;
    if (!_schema) {
      getSupplierCatalogSchema(tableId);
    }
  },
  setSupplierCatalogSchema: (data) => set(() => ({ supplierCatalogSchema: data })),

  getSupplierCatalogs: () => {
    const _supplierCatalogs = get().supplierCatalogs;
    if (_supplierCatalogs.length === 0) {
      getSupplierCatalogs();
    }
  },
  setSupplierCatalogs: (data) => set(() => ({ supplierCatalogs: data })),

  getOrderedProductsSchema: (tableId) => {
    const _schema = get().orderedProductsSchema;
    if (!_schema) {
      getOrderedProductsSchema(tableId);
    }
  },
  setOrderedProductsSchema: (data) => set(() => ({ orderedProductsSchema: data })),

  getOrderProducts: (po_id) => {
    const _orderedProducts = get().orderedProducts;
    if (_orderedProducts.length === 0) {
      getOrderProducts(po_id);
    }
  },
  setOrderProducts: (data) => set(() => ({ orderedProducts: data })),

  reset: () => set(() => ({ ...initialState })),
}));

export { useInventoryStore };
