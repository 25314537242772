import { customAlphabet } from "nanoid";

export * from "./storage";

export const onSearchData = () => {};

export function generateId(str = "") {
  const nanoid = customAlphabet(
    "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
    10
  );

  return str.replace(/[^A-Z0-9]/gi, "_") + nanoid();
}

export function sanitizeName(name) {
  // Replace non-alphanumeric characters with underscores
  return name.replace(/[^a-z0-9]/gi, "_").toLowerCase();
}

export {
  FIELD_TYPE,
  FIELD_KEYS,
  FIELD_ICONS,
  OTHER_FIELDS,
  FIELD_DETAILS,
} from "./fields";
