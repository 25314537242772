import React, { useEffect } from "react";
import { PageContainer } from "../container";
import { Link, useHistory } from "react-router-dom";
import { UserService } from "../../shared/service";
import { TABLES } from "../../shared/constants/tables";
import { getItem, setItem, clear } from "../../shared/utils";

import {
  Button,
  BUTTON_TYPE,
  Input,
  Form,
  Field,
  showError,
  Password,
} from "../../shared/widgets";
import { useAccountStore, useUserStore } from "../../shared/store";
import styles from "./login.module.css";

export function LoginPage() {
  let history = useHistory();

  useEffect(() => {
    let accountId = getItem(TABLES.ACCOUNT_ID);
    if (accountId) {
      onLoginSucess();
    }
  }, []);

  const onFinish = (values) => {
    clear();
    useAccountStore.getState().setLoading(true);

    UserService.signIn(values)
      .then((data) => {
        // Does not work with stores for some reason, values are reset
        // useAccountStore.getState().setAccountId(data.account_id);
        // console.log("Account Id: ", data.account_id);
        // useUserStore.getState().setUserId(data.user_id);

        setItem(TABLES.ACCOUNT_ID, data.account_id); // Temp fix for "account_id removed" bug
        setItem(TABLES.USER_ID, data.user_id);
        setItem(TABLES.USER_EMAIL, values?.email);
        onLoginSucess();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        useAccountStore.getState().setLoading(false);
      });
  };

  const onLoginSucess = () => {
    history.push(`/home`);
  };

  return (
    <PageContainer>
      <Form
        name="normal_login"
        className={styles.loginForm}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Field>
          <div className={styles.header}>
            <div className="header600 center">Welcome Back</div>
            <div className="tiny100 center">Sign in to your account</div>
          </div>
        </Field>
        <Field
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
          className={"flexField"}
        >
          <Input placeholder="Email" type={"email"} />
        </Field>
        <Field
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
          className={"flexField"}
        >
          <Password type="password" placeholder="Password" />
        </Field>
        <Field className={styles.forgotPasswordRow}>
          <Link className={styles.forgotPassword} to="/forgotpassword">
            Forgot password
          </Link>
        </Field>
        <Field>
          <Button type={BUTTON_TYPE.PRIMARY} htmlType="submit" block={true}>
            Sign in
          </Button>
        </Field>
        <Field>
          <div className={styles.singUp}>
            <span className="text400">Don't have an account?</span>
            <Link className={styles.signUpLink} to="/signup">
              Sign Up
            </Link>
          </div>
        </Field>
      </Form>
    </PageContainer>
  );
}
