import { create } from "zustand";
import { ItemService } from "../service";
import { showSuccess } from "../widgets";
import { getFormData, getItemLogs, getSupplierProducts } from "../actions";
// import { useShallow } from 'zustand/react/shallow'

const store = {
  itemId: null,
  itemLogs: null,
  formSchema: null,
  formInstance: null,
  formData: null,
  supplierProducts: null,
  manuallyAddingQuotes: false,
  showQuotationTable: false, // Disable Send RFQ / Manually add quotes btn if true
  releasingPO: null, // Show loader for action button in the selected row and disable other "Release PO" btn
  generatingPO: null, // Show loader for action button in the selected row and disable other "Release PO" btn
};

const useFormStore = create((set, get) => ({
  ...store,
  setItemId: (id) => set(() => ({ itemId: id })),
  setItemLogs: (data) => set(() => ({ itemLogs: data })),
  getItemLogs: (flowId, itemId) => {
    if (!get().itemLogs) {
      getItemLogs(flowId, itemId);
    }
  },
  setFormInstance: (data) => set(() => ({ formInstance: data })),
  setFormSchema: (data) => set(() => ({ formSchema: data })),
  setFormData: (data) => set(() => ({ formData: data })),
  addRow: (connected_model_id) => {
    let _item_id = get().itemId;
    let _schema = get().formSchema;
    let formInstance = get().formInstance;
    let formData = get().formData;
    return ItemService.createLineItem(
      _schema.metadataId,
      _item_id,
      connected_model_id
    )
      .then((res) => {
        console.log(res);

        let _currentRows = formInstance.getFieldValue(connected_model_id) || [];

        const newRow = { id: res.id };

        formInstance.setFieldValue(connected_model_id, [
          ..._currentRows,
          newRow,
        ]);
        formData[connected_model_id].push(newRow);
        set(() => ({ formData: formData }));
      })
      .catch((error) => {
        console.error("Error adding row:", error);
      });
  },
  addReceivableRow: async (connected_model_id, data) => {
    const itemId = get().itemId;
    const schema = get().formSchema;
    
    try {
        const res = await ItemService.addReceivable(
            schema.metadataId,
            itemId,
            connected_model_id,
            data
        );
        
        console.log("value saved");
        await getFormData(schema.metadataId, itemId);
        return res; // Return the response
        
    } catch (error) {
        console.error("Error adding row:", error);
        throw error; // Re-throw the error
    }
},
  updateReceivableRow: (connected_model_id, receivable_id, data) => {
    let _item_id = get().itemId;
    let _schema = get().formSchema;
    let formInstance = get().formInstance;
    let formData = get().formData;
    ItemService.updateReceivable(
      _schema.metadataId,
      _item_id,
      receivable_id,
      connected_model_id,
      data
    )
      .then((res) => {
        console.log("value saved");
        getFormData(_schema.metadataId, _item_id);
      })
      .catch((error) => {
        console.error("Error adding row:", error);
      });
  },
  addBatchRows: (connected_model_id, batchData, onSuccess, onError) => {
    let _item_id = get().itemId;
    let _schema = get().formSchema;
    let formInstance = get().formInstance;
    let formData = get().formData;

    ItemService.createBatch(
      _schema.metadataId,
      _item_id,
      connected_model_id,
      batchData
    )
      .then((res) => {
        const currentRows =
          formInstance.getFieldValue(connected_model_id) || [];

        formInstance.setFieldValue(connected_model_id, [
          ...currentRows,
          ...res,
        ]);
        formData[connected_model_id] = [
          ...formData[connected_model_id],
          ...res,
        ];
        set(() => ({ formData: formData }));
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        console.error(
          "Error adding batch rows:",
          error,
          error.missing_item_codes
        );
        console.log("Error adding batch rows:", error.missing_item_codes);
        if (onError) {
          let errorMsg, errorTitle;
          if (error.missing_item_codes) {
            errorMsg = "These item codes are missing";
          } else {
            errorMsg = error.message || "Unexpected error happened";
          }
          let errorData = {
            message: errorMsg,
            missing_item_codes: error.missing_item_codes || [],
          };
          onError(errorData);
        }
      });
  },

  deleteRow: (connected_model_id, row_index) => {
    let _item_id = get().itemId;
    let _schema = get().formSchema;
    let datatable = get().formData;
    console.log("row_index", datatable);
    console.log("row_index", row_index);
    console.log("row_index", datatable[connected_model_id][row_index]);
    const connected_model_row_id = datatable[connected_model_id][row_index]?.id;

    return ItemService.deleteLineItem(
      _schema.metadataId,
      _item_id,
      connected_model_id,
      connected_model_row_id
    )
      .then((res) => {
        console.log(res);
        // const updatedRows = datatable[connected_model_id].filter((_, index) => index !== row_index);
        // set((state) => ({
        //   formData: {
        //     ...state.formData,
        //     [connected_model_id]: updatedRows
        //   }
        // }));
        // let formInstance = get().formInstance;
        // formInstance.setFieldValue(connected_model_id, updatedRows);
        getFormData(_schema.metadataId, _item_id);
        showSuccess("Item Deleted");
      })
      .catch((error) => {
        console.error("Error deleting row:", error);
      });
  },

  setManuallyAddingQuotes: (manuallyAddingQuotes) => {
    set(() => ({ manuallyAddingQuotes: manuallyAddingQuotes }));
  },
  setShowQuotationTable: (showQuotationTable) => {
    set(() => ({ showQuotationTable: showQuotationTable }));
  },
  setReleasingPO: (releasingPO) => set(() => ({ releasingPO: releasingPO })),
  setGeneratingPO: (generatingPO) =>
    set(() => ({ generatingPO: generatingPO })),
  reset: () => set({ ...store }),
}));

export { useFormStore };
