import { post, get, put, deleteReq } from "./service";

const category = "procurement";

export const ItemService = {
  createLineItem(table_id, item_id, connected_model_id) {
    return post(
      `/${category}/${table_id}/${item_id}/connectedmodel/${connected_model_id}`,
      { body: {} }
    );
  },
  createBatch(model_id, item_id, connected_model_id, data) {
    return post(
      `/${category}/${model_id}/${item_id}/connectedmodel/${connected_model_id}/batch`,
      { body: data }
    );
  },
  deleteLineItem(
    table_id = "purchase_request",
    item_id,
    connected_model_id,
    connected_model_row_id
  ) {
    return deleteReq(
      `/${category}/${table_id}/${item_id}/connectedmodel/${connected_model_id}/${connected_model_row_id}/delete`,
      { body: {} }
    );
  },
  createItem(table_id, data) {
    return post(`/${category}/${table_id}`, { body: data });
  },
  updateItem(table_id = "purchase_request", item_id = "1", data) {
    return post(`/${category}/${table_id}/${item_id}`, { body: data });
  },

  updateLineItemBatch(
    table_id = "purchase_request",
    item_id = "1",
    line_item,
    data
  ) {
    console.log("update data", data);
    return post(
      `/${category}/${table_id}/${item_id}/connectedmodel/${line_item}/batch/update`,
      { body: data }
    );
  },
  updateItemPosition(table_id = "purchase_request", item_id = "1", data) {
    return post(`/${category}/${table_id}/${item_id}/move`, { body: data });
  },
  getItem(table_id = "purchase_request", item_id = "1") {
    return get(`/${category}/${table_id}/${item_id}`);
  },
  listItems(table_id = "purchase_request") {
    return get(`/${category}/${table_id}/list`);
  },
  bill(table_id = "purchase_request", data) {
    return post(`/${category}/bills/${table_id}`, {
      body: data,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    });
  },
  listItemPosition(table_id = "purchase_request") {
    return get(`/${category}/kanban_position/${table_id}`);
  },
  getSupplierList(flowId, itemId) {
    return get(`/${category}/${flowId}/${itemId}/dropdown/suppliers`);
  },
  getSupplierProducts(supplierId) {
    return get(`/${category}/supplier-products/${supplierId}`);
  },
  getSupplierData(supplierId) {
    return get(`/${category}/suppliers/${supplierId}`);
  },
  setQuotationTable(table_id, item_id, data = {}) {
    return post(`/${category}/${table_id}/${item_id}/set-quotation-table`, {
      body: data,
    });
  },
  sendRFQ(table_id = "purchase_request", item_id = "1", data = {}) {
    return post(`/${category}/${table_id}/${item_id}/rfq`, {
      body: data,
    });
  },
  getLiveMetadata(table_id = "purchase_order") {
    return get(`/metadata/live/${table_id}`);
  },
  updateSupplierForm(
    table_id = "purchase_request",
    item_id = "1",
    supplier_id = 1,
    data
  ) {
    return post(
      `/${category}/${table_id}/${item_id}/supplier/${supplier_id}/receive_quote`,
      { body: data }
    );
  },
  releasePO(table_id = "purchase_request", item_id = "1", data = {}) {
    return post(`/${category}/${table_id}/${item_id}/supplier/release_po`, {
      body: data,
    });
  },
  generatePO(
    table_id = "purchase_request",
    item_id = "1",
    quotation_id = 1,
    data = {}
  ) {
    return post(
      `/${category}/${table_id}/${item_id}/supplier/${quotation_id}/generate_po`,
      {
        body: data,
      }
    );
  },
  createPO(table_id, item_id, data = {}) {
    return post(`/${category}/${table_id}/${item_id}/create-po`, {
      body: data,
    });
  },
  analyzeRFQ(table_id = "purchase_request", item_id = "1", data = {}) {
    return post(`/${category}/${table_id}/${item_id}/rfq/analyze`, {
      body: data,
    });
  },
  getSuppliersUsingAI(table_id, item_id, data = {}) {
    return post(`/${category}/${table_id}/${item_id}/suppliers/find`, {
      body: data,
    });
  },
  threewayMatching(
    table_id = "purchase_request",
    item_id = "PKOmXJtGhxQTCG9gz0Y2ppOw",
    data = {}
  ) {
    return post(`/${category}/${table_id}/${item_id}/reconciliation`, {
      body: data,
    });
  },
  requestInvoice(
    table_id = "purchase_request",
    item_id = "PKOmXJtGhxQTCG9gz0Y2ppOw",
    data = {}
  ) {
    return post(`/${category}/${table_id}/${item_id}/invoice/request`, {
      body: data,
    });
  },
  getComments(table_id, item_id) {
    return get(`/${category}/${table_id}/${item_id}/comments`);
  },
  addComment(table_id, item_id, data = {}) {
    return post(`/${category}/${table_id}/${item_id}/comment`, { body: data });
  },
  getItemLogs(table_id, item_id) {
    return get(`/${category}/${table_id}/${item_id}/logs`);
  },
  getPresignedS3Url(key, filetype) {
    return get(`/${category}/presigned-s3-url?key=${key}&filetype=${filetype}`);
  },
  getDropdownValue(model_id, dropdown_id) {
    return get(`/${category}/get-dropdown-value/${model_id}/${dropdown_id}`);
  },
  getDropdownValues(model_id, lookup_fields) {
    return get(
      `/${category}/get-dropdown-values/${model_id}?fields=${lookup_fields.toString()}`
    );
  },

  getDropdownData(dropdownName) {
    return get(`/${category}/get-dropdown-data/${dropdownName}`);
  },
  getDropdownList() {
    return get(`/${category}/get-dropdown-list`);
  },
  addDropdownRow(dropdownName, data) {
    return post(`/${category}/add-dropdown-data/${dropdownName}`, {
      body: data,
    });
  },
  addReceivable(table_id, item_id, connected_model_id, data) {
    return post(`/${category}/add-receivable-data/${item_id}`, { body: data });
  },
  updateReceivable(table_id, item_id, receivable_id, connected_model_id, data) {
    return post(
      `/${category}/update-receivable-data/${item_id}/${receivable_id}`,
      { body: data }
    );
  },
  extractReceivable(data, item_id) {
    return post(`/${category}/extract-receivable-data/${item_id}`, {
      body: data,
    });
  },
  batchAddDropdownRow(dropdownName, data) {
    console.log("data", data);
    return post(`/${category}/batch-add-dropdown-data/${dropdownName}`, {
      body: data,
    });
  },

  updateDropdownRow(dropdownName, rowId, updatedData) {
    return put(`/${category}/update-dropdown-data/${dropdownName}/${rowId}`, {
      body: updatedData,
    });
  },

  deleteDropdownRows(dropdownName, rowIds) {
    return deleteReq(`/${category}/delete-dropdown-rows/${dropdownName}/rows`, {
      body: JSON.stringify({ rowIds }),
    });
  },
};
