import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Clarity from "@microsoft/clarity";

import {
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  SignupPage,
  ActivateAccountPage,
  ActivateUserAccountPage,
} from "./unauth";
import { Spin } from "./shared/widgets";
import { useAccountStore } from "./shared/store";
import { onLoad } from "./shared/actions/onload";
import { LazyLoad } from "./shared/components";

import { PrivateRoute } from "./auth";
// import { FormRuntime } from "./shared/components/form_runtime_final/index.jsx";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

import "./App.css";

// https://stackoverflow.com/questions/76650410/antd-dayjs-typeerror-clone-weekday-is-not-a-function
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

window.Buffer = window.Buffer || require("buffer").Buffer;

const projectId = "q40o7g0m3q";

function App() {
  let { isLoading } = useAccountStore((state) => ({
    isLoading: state.isLoading,
  }));
  useEffect(() => {
    Clarity?.init(projectId);
    onLoad();
  }, []);
  return (
    <div className="App">
      <Spin spinning={isLoading} wrapperClassName="mainLoader">
        <Router>
          <Switch>
            {/* <Route path="/login">
              <FormRuntime />
            </Route> */}
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/public/form/:account_id/:flow_id/:item_id/:form_type">
              <LazyLoad
                component={() => import("./flow/form")}
                componentName={"CardFormRuntime"}
              />
            </Route>
            {/* <Route path="/public/form/:flow_id">
              <LazyLoad
                component={() => import("./flow/form")}
                componentName={"CreatePublicForm"}
              />
            </Route> */}
            <Route path="/resetpassword/:tenant_id/:token/:userId">
              <ResetPasswordPage />
            </Route>
            <Route path="/activate/:userId/:key">
              <ActivateAccountPage />
            </Route>
            <Route path="/user/activate/:userId/:key">
              <ActivateUserAccountPage />
            </Route>
            <Route path="/forgotpassword">
              <ForgotPasswordPage />
            </Route>
            <Route path="/signup">
              <SignupPage />
            </Route>

            <Route path="/">
              <PrivateRoute />
            </Route>
          </Switch>
        </Router>
      </Spin>
    </div>
  );
}

export default App;
