import { InventoryService } from "../service/inventory.service.js";
import { WorkflowService } from "../service/workflow_service.js";
import { useInventoryStore } from "../store";
import { showError } from "../widgets";

export function getProductSchema(tableId) {
  const setProductSchema = useInventoryStore.getState().setProductSchema;
  WorkflowService.getLiveMetadata(tableId)
    .then((res) => {
      setProductSchema(res);
    })
    .catch(() => showError("Can't find product schema"));
}

export function getProducts() {
  const setProducts = useInventoryStore.getState().setProducts;
  InventoryService.getProductsData()
    .then((data) => {
      setProducts(data);
    })
    .catch(() => showError("Error in fetching products"));
}

export function getSupplierCatalogSchema(tableId) {
  const setSupplierCatalogSchema = useInventoryStore.getState().setSupplierCatalogSchema;
  WorkflowService.getLiveMetadata(tableId)
    .then((res) => {
      setSupplierCatalogSchema(res);
    })
    .catch(() => showError("Can't find supplier catalog schema"));
}

export function getSupplierCatalogs() {
  const setSupplierCatalogs = useInventoryStore.getState().setSupplierCatalogs;
  InventoryService.getSupplierCatalog()
    .then((data) => {
      setSupplierCatalogs(data);
    })
    .catch(() => showError("Error in fetching supplier catalogs"));
}

export function getOrderedProductsSchema(tableId) {
  const setOrderedProductsSchema = useInventoryStore.getState().setOrderedProductsSchema;
  WorkflowService.getLiveMetadata(tableId)
    .then((res) => {
      setOrderedProductsSchema(res);
    })
    .catch(() => showError("Can't find ordered products schema"));
}

export function getOrderProducts(po_id) {
  const setOrderProducts = useInventoryStore.getState().setOrderProducts;
  InventoryService.getOrderedProduct(po_id)
    .then((data) => {
      setOrderProducts(data);
    })
    .catch(() => showError("Error in fetching ordered products"));
}
